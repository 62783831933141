var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sync-ctrl-wrapper w-52 flex items-center justify-center absolute z-70 shadow-md"},[_c('div',{staticClass:"w-full bg-white rounded-md z-40 px-4 py-4"},[_c('div',{staticClass:"flex items-center justify-between mb-4"},[_c('h3',{staticClass:"font-bold"},[_vm._v("Update Firmware")]),_c('button',{staticClass:"text-gray-900",on:{"click":_vm.closeSyncCtrl}},[_c('closeFullSvg',{staticClass:"h-5 fill-current"})],1)]),_c('div',{staticClass:"flex flex-col space-y-2"},[(_vm.isBoardConnected)?_c('button',{staticClass:"transition duration-200 ease-in-out bg-green-500 text-white shadow-sm border border-green-600 text-xs py-2 rounded flex-1",class:{
                    'opacity-50 cursor-wait': _vm.isSyncing === true,
                },on:{"click":() => !_vm.isSyncing && _vm.startSync()}},[_vm._v(" "+_vm._s(_vm.isSyncing ? 'Syncing...' : 'Sync')+" ")]):_vm._e(),_c('button',{staticClass:"transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-xs py-2 rounded flex-1",class:{
                    'pointer-events-none opacity-50': _vm.isSyncing === true,
                },on:{"click":_vm.downloadBinary}},[_vm._v(" Download ")])]),(_vm.syncStatus)?_c('div',{staticClass:"block mt-3 px-2 py-3 border rounded text-xs",class:{
                'bg-blue-100 border-blue-500 text-blue-600': _vm.syncStatus === 'pending',
                'bg-green-100 border-green-500 text-green-600': _vm.syncStatus === 'success',
                'bg-red-100 border-red-500 text-red-600': _vm.syncStatus === 'failed'
            }},[_c('span',[_vm._v(_vm._s(_vm.syncMessage))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }