<template>
    <div class="sync-ctrl-wrapper w-52 flex items-center justify-center absolute z-70 shadow-md">
        <div class="w-full bg-white rounded-md z-40 px-4 py-4">
            <div class="flex items-center justify-between  mb-4">
                <h3 class="font-bold">Update Firmware</h3>
                <button
                    class="text-gray-900"
                    @click="closeSyncCtrl"
                >
                    <closeFullSvg class="h-5 fill-current" />
                </button>
            </div>
            <div class="flex flex-col space-y-2">
                <button
                    v-if="isBoardConnected"
                    @click="() => !isSyncing && startSync()"
                    class="transition duration-200 ease-in-out bg-green-500 text-white shadow-sm border border-green-600 text-xs py-2 rounded flex-1"
                    :class="{
                        'opacity-50 cursor-wait': isSyncing === true,
                    }">
                    {{ isSyncing ? 'Syncing...' : 'Sync' }}
                </button>
                <button
                    @click="downloadBinary"
                    class="transition duration-200 ease-in-out bg-white hover:bg-gray-100 shadow-sm border border-gray-300 text-gray-800 text-xs py-2 rounded flex-1"
                    :class="{
                        'pointer-events-none opacity-50': isSyncing === true,
                    }">
                    Download
                </button>
            </div>
            <div
                v-if="syncStatus"
                class="block mt-3 px-2 py-3 border rounded text-xs"
                :class="{
                    'bg-blue-100 border-blue-500 text-blue-600': syncStatus === 'pending',
                    'bg-green-100 border-green-500 text-green-600': syncStatus === 'success',
                    'bg-red-100 border-red-500 text-red-600': syncStatus === 'failed'
                }">
                <span>{{ syncMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { syncBinary } from '@/utils/api/socket/iot';

import { getSessionId } from '@/utils/auth';

export default {
    name: 'IotDeviceSyncCtrl',

    data() {
        return {
            isSyncing: false,
            syncStatus: '',
            syncMessage: '',
        };
    },

    computed: {
        ...mapState('iotDevice', [
            'isBoardConnected',
            'newBuildSrc',
        ]),
    },

    methods: {
        ...mapMutations('iotDevice', [
            'setShowDeviceSyncCtrl',
        ]),

        async startSync() {
            try {
                this.isSyncing = true;

                const sessionId = getSessionId();
                await syncBinary({
                    sessionId,
                    buildSrc: this.newBuildSrc,
                    cb: this.syncStatusWatcher,
                });
            } catch (ex) {
                this.isSyncing = false;
                console.error(ex);
            }
        },

        syncStatusWatcher(syncStatus) {
            this.syncStatus = syncStatus.data.status;
            this.syncMessage = syncStatus.data.message;

            if (this.syncStatus === 'success' || this.syncStatus === 'failed') {
                this.isSyncing = false;
            }
        },

        downloadBinary() {
            const link = document.createElement('a');
            link.setAttribute('href', this.newBuildSrc);
            link.click();
            document.removeChild(link);
        },

        closeSyncCtrl() {
            this.setShowDeviceSyncCtrl(false);
            this.syncStatus = '';
            this.syncMessage = '';
        },
    },

    components: {
        closeFullSvg: () => import('@/assets/img/close.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.sync-ctrl-wrapper {
    bottom: 50px;
    left: 58px;
}
</style>
